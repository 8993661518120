import styled from 'styled-components'
import { rgba } from 'polished'
import {
  spacing,
  colors,
  typography,
} from '../../../styles/utilities/variables'
import StyledHeading from '../../Heading/style'
import StyledText from '../../Text/style'
import StyledTextLink from '../../TextLink/style'

const StyledBikeSelectorResult = styled.div`
  background: ${rgba(colors.secondaryYellow, 0.1)};
`

StyledBikeSelectorResult.Inner = styled.div`
  display: flex;
  flex-direction: column;

  & svg {
    align-self: center;
  }

  & ${StyledHeading.h1} {
    font-family: ${typography.primaryFont};
  }
`

StyledBikeSelectorResult.LinkList = styled.ul`
  list-style: none;
  margin: 0 0 ${spacing.medium} 0 !important;
  padding: 0 !important;
`

StyledBikeSelectorResult.LinkListItem = styled.li``

export default StyledBikeSelectorResult
