import React from 'react'
import { graphql } from 'gatsby'
import { parseSeo } from '../../helpers/seo'
import Layout from '../../views/Layout'
import SEO from '../../views/Seo'
import { BikeSelectorResult } from '../../components/BikeSelector/Result'

export default ({ data, pageContext }) => {
  const page = data.wordpress.page
  const { seo } = page

  return (
    <Layout>
      <SEO {...parseSeo(seo)} />
      <BikeSelectorResult />
    </Layout>
  )
}

export const query = graphql`
  query($id: ID!) {
    wordpress {
      page(id: $id) {
        ...Single_Page
      }
    }
  }
`
