import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import StyledBikeSelectorResult from './style'
import { AppContext } from '../../../config/context'
import { PageContent } from '../../PageContent'
import { Medallion } from '../../Svg'
import { Heading } from '../../Heading'
import { Text } from '../../Text'
import { TextLink } from '../../TextLink'
import { Container } from '../../Container'
import { TEXT_SIZES, SPACING, COLORS } from '../../../helpers/constants'
import { Divider } from '../../Divider'
import { Content } from '../../Content'
import { getUrl } from '../../../helpers/url'
import { ProductCard } from '../../ProductCard'
import { Grid } from '../../Grid'
import { useTranslation } from '../../../hooks/useTranslation'

const BikeSelectorResult = props => {
  const [state, dispatch] = useContext(AppContext)
  const { bikeSelectorQuestions, products } = state

  const getTranslation = useTranslation()

  const allAnswerProductIds = bikeSelectorQuestions.reduce((acc, question) => {
    let idsOnCheckedAlternative = question.answerAlternatives.reduce(
      (altAcc, alternative) => {
        return alternative.checked
          ? [...altAcc, ...alternative.answerProducts]
          : altAcc
      },
      []
    )
    return [...acc, ...idsOnCheckedAlternative]
  }, [])

  const matchingProducts = products.filter(product =>
    allAnswerProductIds.includes(String(product.productId))
  )

  const getPercentage = productId => {
    let count = allAnswerProductIds.reduce(
      (acc, id) => acc + (id === String(productId)),
      0
    )

    let percentage = Math.round((count / bikeSelectorQuestions.length) * 100)
    return percentage
  }

  // Sort based on percentage
  matchingProducts.sort(function(a, b) {
    if (getPercentage(a.productId) > getPercentage(b.productId)) {
      return -1
    }
    if (getPercentage(b.productId) > getPercentage(a.productId)) {
      return 1
    }
    return 0
  })

  return (
    <StyledBikeSelectorResult>
      <Container narrow>
        <PageContent>
          <StyledBikeSelectorResult.Inner>
            <Medallion />
            <Divider
              size={SPACING.X_LARGE}
              line={COLORS.SECONDARY_LIGHT_BLUE}
            />
            <Content>
              <Heading size={1} type={'h1'}>
                {getTranslation('theResultText')}
              </Heading>

              {bikeSelectorQuestions.map((question, qIndex) => {
                let checkedAlternative = question.answerAlternatives.reduce(
                  (acc, alternative) => {
                    return alternative.checked ? alternative : acc
                  },
                  {}
                )

                return checkedAlternative ? (
                  <React.Fragment key={qIndex}>
                    <Text>
                      <strong>
                        {qIndex + 1}. {checkedAlternative.answerResultTitle}
                      </strong>{' '}
                      {checkedAlternative.answerResultText}
                    </Text>
                    {checkedAlternative.answerResultLinks && (
                      <StyledBikeSelectorResult.LinkList>
                        {checkedAlternative.answerResultLinks.map(
                          (link, index) => {
                            const {
                              link: { url, title },
                            } = link
                            return (
                              <StyledBikeSelectorResult.LinkListItem
                                key={index}
                              >
                                <TextLink
                                  icon={'keyboard_arrow_right'}
                                  to={getUrl(url)}
                                  text={title}
                                />
                              </StyledBikeSelectorResult.LinkListItem>
                            )
                          }
                        )}
                      </StyledBikeSelectorResult.LinkList>
                    )}
                  </React.Fragment>
                ) : null
              })}
            </Content>

            <Divider size={SPACING.X_SMALL} />
            <Text size={TEXT_SIZES.MEDIUM}>
              {getTranslation('recommendedOnYourAnswerText')}
            </Text>
            <Divider size={SPACING.X_SMALL} />

            <Grid col={3} mobileCol={2}>
              {matchingProducts.map((product, index) => (
                <ProductCard
                  key={product.productId}
                  product={product}
                  percentage={`${getPercentage(product.productId)}%`}
                />
              ))}
            </Grid>
          </StyledBikeSelectorResult.Inner>
        </PageContent>
      </Container>
    </StyledBikeSelectorResult>
  )
}

BikeSelectorResult.propTypes = {
  children: PropTypes.any,
}

export { BikeSelectorResult }
